._qGthJ {
  font-family: Arial, Helvetica, sans-serif;
  box-sizing: border-box;
  letter-spacing: -0.2px;
}

/* indicator */

._3Kg-b {
  display: flex;
  justify-content: center;
  font-size: 10px;
  margin-bottom: 5em;
  margin-top: 3em;
}

._18XWA {
  table-layout: auto;
  border-spacing: 0px;
  display: table;
  box-sizing: border-box;
  border-color: unset;
  width: 94em;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

._3lsIe {
  padding: 1.5em;
  text-align: left;
  vertical-align: baseline;
  text-align: center;
}

._PynUC {
  display: flex;
  justify-content: space-between;
}

._PynUC span:last-child {
  color: black;
  font-size: 15px;
  letter-spacing: 0.4px;
  font-weight: 300;
}
._2BZbs {
  margin: 0;
  text-align: left;
  vertical-align: baseline;
  text-align: center;
  padding: 1.2em 0.7em;
  font-size: 15px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

._32kWB {
  color: #1976d2;
  font-size: 17px;
}

._2MlMJ {
  font-size: 15px;
  vertical-align: baseline;
  text-align: center;
  padding: 1.2em 0.7em;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

._2lGkw {
  font-size: 14px;
}

._qcjXT {
  vertical-align: baseline;
  text-align: center;
  padding: 1.2em 0.7em;
  font-size: 15px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

._kcP3k {
  vertical-align: baseline;
  text-align: center;
  padding: 1.2em 0.7em;
  font-size: 15px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
